import React from 'react'
import styled from 'styled-components'
import { useTestResult } from '../../../contexts/testResultContext'
import ResultCard from './ResultCard'

const StyledResultsCardsContainer = styled.div`
  display: flex;
  gap: 3vh;
  justify-content: center;
  margin-top: 5vh;
  @media (max-width: 640px) {
    margin-top: 30px;
    flex-direction: column;
  }
`;

const resultsCardsDetails =
{
    downloadSpeed: { icon: 'cloud_download', text: 'تحميل', unit: 'ميغابت في الثانية' },
    uploadSpeed: { icon: 'cloud_upload', text: 'رفع', unit: 'ميغابت في الثانية' },
    latency: { icon: 'monitor_heart', text: 'latency', unit: 'ثواني' },
}

export default function ResultsCardsContainer() 
{
    const { testResult } = useTestResult()

    return (
        <StyledResultsCardsContainer>
            { Object.keys( resultsCardsDetails ).map( ( cardKey ) =>{

                const currentCard = resultsCardsDetails[ cardKey ]
                return <ResultCard key={ cardKey } dataKey={ cardKey } icon={ currentCard.icon } text={ currentCard.text } unit={ currentCard.unit }  data={ testResult[ cardKey ] }/>
            }) }
        </StyledResultsCardsContainer>
    )
}