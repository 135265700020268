import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTestResult } from '../../../contexts/testResultContext';
import { useAppAttributes } from '../../../contexts/appAttributesContext';
import { getData, testDownloadAndUploadSpeed } from '../../../utils/api.js';

export default function TestButton({ fake }) {
    const { setTestResult } = useTestResult();
    const { loading, setLoading, setError } = useAppAttributes();

    return (
        <Button
            variant="contained"
            disabled={loading}
            startIcon={
                <CloudUploadIcon sx={{
                    fontSize: '2rem !important',
                    marginRight: '10px',
                    color: 'white', // Change the color of the icon
                }} />
            }
            sx={{
                fontSize: '3rem',
                backgroundColor: '#123456', // Change the color of the button
                '&:hover': {
                    backgroundColor: '#234567', // Change the color of the button on hover
                },
            }}
            onClick={() => {
                testSpeed(setTestResult, setLoading, setError);
            }}
        >
            {loading ? <CircularProgress size={48} /> : 'بدء'}
        </Button>
    );

    async function testSpeed(setTestResult, setLoading, setError) {
        setError(false);
        setLoading(true);
        if (fake) {
            await getData("")
                .then((res) => {
                    setTestResult(res.data);
                })
                .catch(function (error) {
                    setError(true);
                });
        } else {
            await testDownloadAndUploadSpeed().then(res => {
                setTestResult(res);
            }).catch(function (error) {
                setError(true);
            });
        }
        setLoading(false);
    }
}