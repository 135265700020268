const axios = require("axios");
const url = "https://patient2.khalid.top/api/speedTest/config";

const instance = axios.create({
  baseURL: url,
  timeout: 1000,
  //cors
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

export function getData() {
  const data = instance.get("/");
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 5000);
  });
}

export function postData(data) {
  return instance.put("/", data);
}

export function getAdminData() {
  return instance.get("/");
}
//the ex[ected response is jpg file 2
const instance2 = axios.create({
  baseURL: "https://patient2.khalid.top/api/download",
  timeout: 100000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "image/jpeg",
  },
});
const instance3 = axios.create({
  baseURL: "https://patient2.khalid.top/api/upload",
  timeout: 100000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export async function testDownloadAndUploadSpeed() {
  async function startDownloadTest() {
    try {
      const startTime = new Date().getTime();

      const downloadSpeed = await new Promise((resolve, reject) => {
        instance2.get(`/?nocache=${new Date().getTime()}`, { // Add a unique query parameter
          responseType: "blob",
          onDownloadProgress: function (progressEvent) {
            if (progressEvent.lengthComputable) {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if (percentCompleted === 100) {
                const endTime = new Date().getTime();
                const durationSeconds = (endTime - startTime) / 1000; // Convert ms to seconds
                const fileSizeBytes = 5000000; // 5 MB
                const speedMbps = (fileSizeBytes * 8) / (durationSeconds * 1024 * 1024); // Convert bytes to bits, then to Mbps
                resolve(speedMbps.toFixed(2));
              }
            }
          },
        }).catch(reject);
      });

      console.log(downloadSpeed);
      return downloadSpeed;
    } catch (error) {
      // existing error handling code...
    }
  }
  async function startUploadTest() {
    try {
      const startTime = new Date().getTime();

      // Create a blob object
      const dataSize = 3 * 1024 * 1024; // 2 MB
      const data = new Blob([new ArrayBuffer(dataSize)], {
        type: "application/octet-stream",
      });

      // Create a FormData object and append the blob as a file
      const formData = new FormData();
      formData.append("testFile", data, "test.bin");

      const response = await instance3.post("/", formData);
      const endTime = new Date().getTime();
      const durationSeconds = (endTime - startTime) / 1000; // Convert ms to seconds
      const speedMbps = (dataSize * 8) / (durationSeconds * 1024 * 1024); // Convert bytes to bits, then to Mbps
      return speedMbps.toFixed(2);
    } catch (error) {
      // existing error handling code...
    }  }
  return {
    downloadSpeed: await startDownloadTest(), uploadSpeed: await startUploadTest() ,
    downloaded: 20,
    uploaded: 5,
    latency: 18,
    bufferBloat: 98,
    userLocation: "Madinah , Ksa",
    userIp: "197.35.84.32",
    server: "ESLAM",
    os: "server"
}

}
