import styled from 'styled-components'
import { useTestResult } from '../../../contexts/testResultContext'
import ClientDetail from './ClientDetail'

const StyledClientDetails = styled.div`
    z-index: 1;
    width: 100%;
    display: flex;  
    flex-direction: row;
    margin-top: 5vh;
    gap: 10px;
    justify-content: space-between;
    direction: rtl;
`

const clientDetailsObject = {
    userIp: { icon: 'wifi', title: 'عنوان IP' },
    userLocation: { icon: 'pin_drop', title: 'الموقع' },
    server: { icon: 'language', title: 'الخادم' },
    os: { icon: 'album',title: 'نظام التشغيل' },
};

export default function ClientDetails() {

    const { testResult } = useTestResult()
    return (
        <StyledClientDetails>

            { Object.keys( clientDetailsObject ).map( ( detailKey  ) => {
                
                return <ClientDetail key={ detailKey } detailKey={ detailKey } icon={ clientDetailsObject[ detailKey ].icon } data={ testResult[ detailKey ] } title={ clientDetailsObject[ detailKey ].title }/>

            })}
        </StyledClientDetails>
    )
}