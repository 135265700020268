import React from 'react'
import styled from 'styled-components'
import { AppAttributesProvider } from '../../../../contexts/appAttributesContext'
import SpeedTestChart from '../SpeedTestChart'
import TestButton from '../TestButton'

const StyledSpeedDetails = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`
export default function SpeedDetails({fake  }) { 

    return (
        <StyledSpeedDetails>

            <AppAttributesProvider>

                <SpeedTestChart/>

                <TestButton
                fake={fake}
                />

            </AppAttributesProvider>

        </StyledSpeedDetails>
    )
}
