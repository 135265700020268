import React from 'react'
import styled from 'styled-components'
import { TestResultProvider } from '../../contexts/testResultContext'
import ResultsCardsContainer from './components/ResultsCardsContainer'
import ClientDetails from './components/ClientDetails'
import SpeedDetails from './components/SpeedDetails/SpeedDetails'

const StyledSpeedTestPage = styled.div`

    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3vh 0vw ;
    flex-direction: column;
    @media ( max-width: 640px )
    {
        min-width: 100vw;
        min-height: 100vh;
    }
`

const StyledTestDetails = styled.div`
  position: relative;
  display: flex;
    flex-direction: column;
  justify-content: center;
  gap: 9vh;
  padding: 3vh 2vw 0 2vw;
  min-height: 100vh;
  min-width: 100vw;
  @media (max-width: 640px) {
    padding: 5vh  0 ;
    min-width: 100vw;
    min-height: 100vh;
  }
`;

export default function SpeedTestPage({fake}) {

    return (
        <StyledSpeedTestPage>

            <StyledTestDetails>

                <TestResultProvider>


                    <SpeedDetails fake={fake} />

                    <ResultsCardsContainer />
                    
                    
                </TestResultProvider>

            </StyledTestDetails>`

        </StyledSpeedTestPage>
    )
}
