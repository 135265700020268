import SpeedTestPage from './pages/speed-test/SpeedTestPage';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom'
import HomePage from './pages/home/home'
import Admin from './pages/admin/home'




function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/adminXs" element={<Admin />} />
        <Route path="/speedtest" element={<SpeedTestPage fake={true} />}  />
        <Route path="/speedtst" element={<SpeedTestPage fake={false} />}  />
      </Routes>
    </div>
  );
}

export default App;
