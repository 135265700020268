import React, { useState, useEffect } from "react";
import { Box, Typography, Slider, Button } from "@mui/material";
import { Link } from "react-router-dom";

// Initialize the AudioContext outside of the component to avoid re-creation on re-renders
const audioContext = new (window.AudioContext || window.webkitAudioContext)();

const Scroller = ({ color, setColor, value, setValue }) => {
  // Create and manage oscillator and gainNode outside of event handlers
    const [gainNode, setGainNode] = useState(null);
    const [oscillator1, setOscillator1] = useState(null);
    const [oscillator2, setOscillator2] = useState(null);


  // Start continuous sound
const startSound = (frequency) => {
  // If there's an existing sound, just update the frequency
  if (oscillator1 && oscillator2) {
    oscillator1.frequency.value = frequency;
    // Set the second oscillator to have a slightly different frequency to create the beat effect
    oscillator2.frequency.value = frequency + 20; // Adjusted for a more pronounced beat
    return;
  }

  // Create the first OscillatorNode instance with a sine wave
  const newOscillator1 = audioContext.createOscillator();
  newOscillator1.type = "sine";
  newOscillator1.frequency.value = frequency;

  // Create the second OscillatorNode instance with a sine wave, slightly offset in frequency
  const newOscillator2 = audioContext.createOscillator();
  newOscillator2.type = "sine";
  newOscillator2.frequency.value = frequency + 20; // Adjusted for a more pronounced beat

  // Create a GainNode to control the overall volume
  const newGainNode = audioContext.createGain();

  // Connect both oscillators to the gain node, and then to the destination
  newOscillator1.connect(newGainNode);
  newOscillator2.connect(newGainNode);
  newGainNode.connect(audioContext.destination);

  // Initially set a moderate gain value to avoid clipping
  newGainNode.gain.setValueAtTime(1.8, audioContext.currentTime);

  // Start both oscillators
  newOscillator1.start();
  newOscillator2.start();

  // Update global references to the new oscillators and gain node
  setOscillator1(newOscillator1);
  setOscillator2(newOscillator2);
  setGainNode(newGainNode);
};

const stopSound = () => {
  if (gainNode) {
    gainNode.gain.exponentialRampToValueAtTime(
      0.00001,
      audioContext.currentTime + 0.1
    );
    // Stop the oscillator after a short duration to create a dashed sound
    const stopOscillator = () => {
        if (oscillator1) {
            oscillator1.stop(audioContext.currentTime + 0.1);
            setOscillator1(null);
        }
        if (oscillator2) {
            oscillator2.stop(audioContext.currentTime + 0.1);
            setOscillator2(null);
        }
            
      };
      stopOscillator();

      // Add the timeouts to the array
      
  
    // Call cancelAllTimeouts when needed
  }
};


  // Adjust sound based on slider value
  const handleSliderChange = (event, newValue) => {
    const newColor = `hsl(${Math.floor(newValue * 1.2)}, 100%, 50%)`;
    setColor(newColor);

    const frequency = 659.25 + (newValue / 100) * (880 - 659.25); // Mapping slider value to frequency
    startSound(frequency);

    setValue(newValue);
  };

  useEffect(() => {
    // Ensure sounds stop playing when component unmounts
    return () => stopSound();
  }, []);
    
    
    const [disabled, setDisabled] = useState(true)
    
    
    
    
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row-reverse",
        padding: 2,
        mr: 2,
        paddingBottom: 2,
      }}
    >
      <Box
        sx={{
          minHeight: 300,
          height: "40vh",
          backgroundColor: "transparent",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "30%",
          maxWidth: 200,
                  borderRadius: 7,
          visibility: disabled ? "hidden" : "visible",
        }}
      >
        <Slider
            sx={{
                '& input[type="range"]': {
                    display: "none",
                },
                width: "100%",
                backgroundColor: "transparent",
                color: "transparent",
            }}
            color="success"
            orientation="vertical"
            defaultValue={0}
            valueLabelDisplay="off" // Hide the label
            onChange={handleSliderChange}
            onChangeCommitted={stopSound}
            disabled={disabled}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "70%",
          height: "100%",
        }}
      >
        <Link to="/speedtest" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="success"
            sx={{
              mt: 2,
              backgroundColor: "#3f51b5",
              "&:hover": { backgroundColor: "#303f9f" },
              padding: 2,
              fontSize: 20,
              borderRadius: 7,
            }}
          >
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              color="white"
              sx={{
                fontWeight: 600,
                fontSize: 30,
                fontFamily: "Cairo",
                letterSpacing: 1,
                textTransform: "uppercase",
                textAlign: "center",
                padding: 2,
              }}
            >
              Speed Test
            </Typography>
          </Button>
        </Link>
        <Button
          variant="contained"
          color="success"
          sx={{
            mt: 2,
            backgroundColor: "#3f51b5",
            "&:hover": { backgroundColor: "#303f9f" },

            borderRadius: 7,
          }}
          onClick={() => setDisabled(!disabled)}
        >
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            color="white"
            sx={{
              fontWeight: 100,
              fontSize: 3,
              fontFamily: "Cairo",
              letterSpacing: 1,
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            sss
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Scroller;
