import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Scroller from "./components/scroller";



function ScrollColorChangeComponent() {
    const [color, setColor] = useState("hsl(0, 100%, 50%)"); // Initial color
    const [value, setValue] = useState(0); // Initial value
    const [text, setText] = useState("ابدا"); // Initial text
    useEffect(() => {
       if (value < 20) {
            setText("جدا ضعيف");
        } else if (value < 30) {
            setText("ضعيف");
        } else if (value < 50) {
            setText("متوسط");
        } else if (value < 70) {
            setText("جيد");
        } else if (value < 85) {
            setText("قوي");
        } else if (value < 100) {
            setText("ممتاز");
        }
    }, [value]);


  return (
    <Box
      sx={
        {
           display: "flex",
          flexDirection: "column",
                  alignItems: "center",
                  position: "relative", 
                  height: "80vh",
                  width: "100vw",
                  justifyContent: "space-between",
        }
      }
    >
      {/* Wide, Short Box */}
      <Box
        sx={{
                  width: "100%",
                  display: "flex",
            justifyContent: "center",
          height: 200,
          backgroundColor: color,
                  borderRadius: 7,
          marginX: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "white",
            fontSize: 30,
          }}
              >
                <Typography variant="h4" component="h2" gutterBottom color={value > 50 ? 'primary' : 'secondary'} sx={{
                    textShadow: "1px 1px 1px black",
                      fontWeight: "bold",
                      letterSpacing: 1,
                      textTransform: "uppercase",
                      textAlign: "center",
                      padding: 2,
                      fontFamily: "Cairo, sans-serif", 
                      fontSize: 60,
                      borderRadius: 7,
                        
                }}     >               
                      {text}
                    </Typography>
        </Box>
      </Box>

          {/* Tall, Thin Box */}
          
      <Scroller
        color={color}
        setColor={setColor}
        value={value}
        setValue={setValue}
          />
          
    </Box>
  );
}

export default ScrollColorChangeComponent;
