import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { getAdminData, postData, testDownloadAndUploadSpeed } from "../../utils/api";
import { CircularProgress } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import UploadIcon from "@mui/icons-material/CloudUpload";
import SpeedIcon from "@mui/icons-material/Speed";
import TimerIcon from "@mui/icons-material/Timer";
import StorageIcon from "@mui/icons-material/Storage";


function ScrollColorChangeComponent() {
  const [data, setData] = useState({
    downloadSpeed: 0,
    uploadSpeed: 0,
    downloaded: 0,
    uploaded: 0,
    latency: 0,
    bufferBloat: 0,
  });

  useEffect(() => {
    getAdminData().then((res) => {
      setData(res.data);
    });
  }, []);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  // Add a new state variable
  const [isSaving, setIsSaving] = useState(false);

  const editData = async () => {
    setIsSaving(true);
    await postData(data);
    setIsSaving(false);
  };


  return (
    // Assuming handleChange, editData, data, and isSaving are defined elsewhere in your code

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        height: "100%",
        paddingTop: 2,
        width: "100vw",

        justifyContent: "space-between",
        backgroundColor: "#5C6BC0", // Indigo 400
        color: "#EDE7F6", // Indigo 50 for text
        fontWeight: "bold",
        borderRadius: 2,
        padding: "20px 0",
      }}
    >
      {/* Use InputProps to add icons to TextField components */}
      <TextField
        label="سرعة التحميل"
        name="downloadSpeed"
        value={data.downloadSpeed}
        onChange={handleChange}
        color="primary"
        variant="outlined"
        InputProps={{
          startAdornment: <DownloadIcon sx={{ color: "#FFF59D" }} />, // Yellow 200 for icons
          inputProps: {
            // Apply styles directly to the input element
            style: {
              textAlign: "center", // Center the text
              fontSize: "1.5rem", // Increase the font size
            },
          },
        }}
        sx={{
          width: "90%", // Adjust width
          marginY: 1,
          "& .MuiInputLabel-root": { color: "#EDE7F6" }, // Label color
          "& .MuiOutlinedInput-root": {
            color: "#EDE7F6",
            "& fieldset": { borderColor: "#FFF59D" }, // Border color
          },
        }}
      />

      {/* Repeat for other fields, customizing icons as appropriate */}
      <TextField
        label="سرعة الرفع"
        name="uploadSpeed"
        value={data.uploadSpeed}
        onChange={handleChange}
        variant="outlined"
        InputProps={{
          startAdornment: <UploadIcon sx={{ color: "#FFF59D" }} />,
          inputProps: {
            // Apply styles directly to the input element
            style: {
              textAlign: "center", // Center the text
              fontSize: "1.5rem", // Increase the font size
            },
          },
        }}
        sx={{
          width: "90%", // Adjust width
          marginY: 1,
          "& .MuiInputLabel-root": { color: "#EDE7F6" },
          "& .MuiOutlinedInput-root": {
            color: "#EDE7F6",
            "& fieldset": { borderColor: "#FFF59D" },
          },
        }}
      />
      <TextField
        label="المحمل"
        name="downloaded"
        value={data.downloaded}
        onChange={handleChange}
        variant="outlined"
        InputProps={{
          startAdornment: <DownloadIcon sx={{ color: "#FFF59D" }} />,
        }}
        sx={{
          width: "90%", // Adjust width
          marginY: 1,
          direction: "rtl",
          "& .MuiInputLabel-root": { color: "#EDE7F6" },
          "& .MuiOutlinedInput-root": {
            color: "#EDE7F6",
            "& fieldset": { borderColor: "#FFF59D" },
          },
        }}
      />
      <TextField
        label="المرفوع"
        name="uploaded"
        value={data.uploaded}
        onChange={handleChange}
        variant="standard"
        InputProps={{
          startAdornment: <UploadIcon sx={{ color: "#FFF59D" }} />,
        }}
        sx={{
          width: "90%", // Adjust width
          marginY: 1,
          "& .MuiInputLabel-root": { color: "#EDE7F6" },
          "& .MuiOutlinedInput-root": {
            color: "#EDE7F6",
            "& fieldset": { borderColor: "#FFF59D" },
          },
        }}
      />
      <TextField
        label="الاستقرار"
        name="latency"
        value={data.latency}
        onChange={handleChange}
        variant="outlined"
        InputProps={{
          startAdornment: <TimerIcon sx={{ color: "#FFF59D" }} />,
        }}
        sx={{
          width: "90%", // Adjust width
          marginY: 1,
          "& .MuiInputLabel-root": { color: "#EDE7F6" },
          "& .MuiOutlinedInput-root": {
            color: "#EDE7F6",
            "& fieldset": { borderColor: "#FFF59D" },
          },
        }}
      />
      <TextField
        label="التخزين المؤقت"
        name="bufferBloat"
        value={data.bufferBloat}
        onChange={handleChange}
        variant="outlined"
        InputProps={{
          startAdornment: <StorageIcon sx={{ color: "#FFF59D" }} />,
        }}
        sx={{
          width: "90%", // Adjust width
          marginY: 1,
          "& .MuiInputLabel-root": { color: "#EDE7F6" },
          "& .MuiOutlinedInput-root": {
            color: "#EDE7F6",
            "& fieldset": { borderColor: "#FFF59D" },
          },
        }}
      />

      <Button
        onClick={testDownloadAndUploadSpeed}
        variant="contained"
        color="secondary"
        disabled={isSaving}
        startIcon={
          isSaving ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <SaveIcon />
          )
        }
        sx={{
          marginTop: 2,
          backgroundColor: "#FFCA28", // Amber 400
          "&:hover": { backgroundColor: "#FFA000" }, // Amber 700
          color: "#5C6BC0", // Contrast color for the button text/icon
        }}
      >
        تحديث
      </Button>
    </Box>
  );
}

export default ScrollColorChangeComponent;
